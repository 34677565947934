<template>
  <div>
    <div>
      <div class="content1">
        <div class="all">
          <div class="SearchBar">
            <!--搜索框-->
            <el-form
                ref="search"
                :inline="true"
                :model="search"
                size="small"
                label-width="70px"
            >
              <el-form-item  label="时间段">
                <!-- @change="getList(1)" -->
                <el-date-picker
                    v-model="time"
                    size="mini"
                    style="width:300px"
                    type="datetimerange"
                    
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    clearable
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
              </el-form-item>
              <!-- <el-form-item label="巡检区域" label-width="80px">
                <el-select
                    v-model="search.projectType"
                    clearable
                    size="mini"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="item in TypeList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="事件类型" label-width="80px">
                <el-cascader
                  size="mini"
                  style="width: 170px ;"
                  :options="eventTypeList"
                  v-model="eventTypeIdList"
                  :props="{  label:'name' , value:'id' ,multiple: true   }"
                  separator='>'
                  clearable
                  collapse-tags
                @change="deformation"
                  >
                </el-cascader>
              </el-form-item>
              <el-form-item label="状态" label-width="55px">
                <el-select
                    v-model="search.state"
                    clearable
                    size="mini"
                    
                    placeholder="请选择"
                >
                <!-- @change="getList(1)" -->
                  <el-option
                      v-for="item in TypeList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="巡线员" label-width="70px">
                <!-- @change="getList(1)" -->
                <el-select
                    v-model="search.userId"
                    clearable
                    
                    size="mini"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="item in excutorDownArray"
                   :key="item.id"
                   :label="item.username"
                   :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="关键字" label-width="70px">
                <el-input
                    v-model="search.searchText"
                    placeholder="请输入任务编号/地址​​"
                    size="mini"
                    style="width:300px"
                    class="SearchInput"
                    clearable
                ></el-input>
              </el-form-item>
              <el-form-item label-width="20px" label=" ">
                <div>
                  <el-button type="primary" size="mini" @click="getList(1)">查询</el-button>
                  <el-button type="warning" size="mini" @click="reset">重置</el-button>
                  <el-button type="primary" plain size="mini"
                  v-if="$anthButtons.getButtonAuth('deriveAbnormalIncident')"
                   @click="derive">导出</el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="table">
            <!--数据表格-->
            <el-table
                ref="tableData"
                :data="tableData"
                style="width: 100%;"
                border
                height="calc(100vh - 348px)"
                :stripe="true"
                :default-sort="{ prop: 'createTime', order: 'descending' }"
                :header-cell-style="{
                  color: '#333',
                  fontFamily: 'MicrosoftYaHeiUI',
                  fontSize: '14px',
                  fontWeight: 900,
                  textAlign:'center',
                  background: '#f8f8f9',
                }"
            >
              <el-table-column
                  type="index"
                  label="序号"
                  width="70"
                  align="left"
              >
              </el-table-column>
              <el-table-column
                  prop="taskCode"
                  label="任务编号"
                  align="left"
                  width="170"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  prop="eventCodeIdConfirmName"
                  label="事件类型"
                  align="left"
                  width="180"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  prop="eventAddressConfirm"
                  label="地址"
                  align="left"
                  width="240"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  prop="projectNumber"
                  label="异常项"
                  align="left"
                  width="250px"
                  show-overflow-tooltip
              >
                <template slot-scope="{row}" v-if="row.exception">
                  <div v-for="(item,index) in row.exception.split(',')" :key="index">{{item}}</div>  
                </template>
              </el-table-column>
              <el-table-column
                  prop="executorPeopleName"
                  label="巡线员"
                  align="left"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  label="巡检时间"
                  align="left"
                   width="150"
              >
              </el-table-column>
              <el-table-column
                  prop="duration"
                  label="巡检时长"
                  align="left"
                  width="150"
              >
              <template slot-scope="scope">
                <!-- {{(scope.row.duration/60).toFixed(2)}} <span>分钟</span> -->
                {{parseInt(scope.row.duration/60/60%24)}} <span>小时</span>
                {{parseInt(scope.row.duration/60%60)}} <span>分钟</span>
                {{parseInt(scope.row.duration%60)}} <span>秒</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="projectNumber"
                  label=""
                  align="left"
                  :render-header="renderHeader"
                  width="120"
              >
              <template slot-scope="scope">
                <div style="color: #1082ff; cursor: pointer" v-if="scope.row.processedSum > 0" @click="appear(scope.row)">{{scope.row.processed}}/{{scope.row.processedSum}}</div>
                  <div v-else>{{scope.row.processed}}/{{scope.row.processedSum}}</div>
                 <!-- {{scope.row.processed}}/{{scope.row.processedSum}} -->
                </template>
              </el-table-column>
              <el-table-column
                  prop="equipmentStatus"
                  label="状态"
                  align="left"
                  width="80"
              >
              <template slot-scope="{row}">
              <div v-if="row.processedSum == '0'" >
                       --
                    </div> 
                    <el-tag v-else-if="row.processedSum <= row.processed"  type='success' size="mini">
                       已处理
                    </el-tag> 
                    <el-tag v-else size="mini" style="color: #E83434;border: 1px solid #F5A7A7;background: #FFF4EB;">
                        待处理
                    </el-tag> 
                  </template>
              </el-table-column>
              <el-table-column
                  prop="projectNumber"
                  label="操作"
                  align="center"
                  width="85"
              >
                <template slot-scope="{row}">
                  <el-button type="primary" size="mini" 
                  v-if="$anthButtons.getButtonAuth('lookAbnormalIncident')"
                  @click="showDetil(row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                  :current-page="search.current"
                  :background="true"
                  :page-sizes="[10,30, 50, 100]"
                  :page-size="search.size"
                  layout="total, prev, pager, next, sizes, jumper"
                  :total="total"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {dicByType,} from "@/RequestPort/maintenance";
import { getUserList} from "@/RequestPort/maintenance/task";
import { dataPage,excelEventData} from "@/RequestPort/checkTask/task";
import { exportMethod } from '../../common/js/exportExcel'
export default {
  name: "workLocus",
  data(){
    return{
      // 查询条件
      search:{
        current:1,
        size: 100,
        isHav:1
      },
      // 状态
      TypeList:[
        {name:'已处理',id:1},
        {name:'待处理',id:0},
      ],
      time:[],
      // 总数
      total:0,
      // 列表
      tableData:[],
      // 巡线员
      excutorDownArray:[],
      // 事件类型
      eventTypeList:[],
      // 事件类型model
      eventTypeIdList:[]
    }
  },
  mounted(){
    this.getList()
    getUserList({type:2}).then((e) => {
      this.excutorDownArray = e.data;
    });
    this.showEventTypeList()
  },
  methods:{
    /**
     * 导出
     */
    derive(){
      let data = {...this.search,...{isHavType:1}}
      excelEventData(data).then(res => {
        exportMethod(res, '异常事件')
      })
    },
    /**
     * 上报事件
     */
    appear(row){
      // row:JSON.stringify(row)
      // this.$router.push({ path: '/home/incident', query: {id:row.eventId } })
      let chakan = this.$router.resolve({
					path: '/home/incident',      //跳转目标窗口的地址
					query:  {id:row.eventId }
				})
				window.open(chakan.href);
    },
    /**
     * 查看详情
     */
    showDetil(row){
        // this.$router.push({ path: '/home/eventPollingDesc', query: { id: row.tplId,row:JSON.stringify(row)} })
        let chakan = this.$router.resolve({
					path: '/home/eventPollingDesc',      //跳转目标窗口的地址
					query:   { id: row.tplId,row:JSON.stringify(row)}
				})
				window.open(chakan.href);
    },
    /**
     * 重置
     */
    reset(){
      this.search={
        current:1,
        size: 100,
        isHav:1
      }
      this.time = []
      this.eventTypeIdList = []
      this.getList()
    },
    /**
     * 事件类型 选择事件
     */
    deformation(){
      let text = []
      this.eventTypeIdList.forEach(el => {
        text.push(el[1])
      })
      this.search.eventType = text.join(',')
      // this.search.eventType = this.eventTypeIdList[1]
      // this.getList(1)
    },
    /**
     * 获取事件类型
     */
    showEventTypeList(){
      dicByType({parentCode:'GAS_PATROL_EVENT',level:2}).then(res=>{
        if(res.code===200){
          for(let i=0;i<res.data.length;i++){
					let el=res.data[i]
					if(!el.children){
						res.data.splice(i,1)
						i=i-1
					}
				}
          this.eventTypeList = res.data
        }
      })
    },
    /**
     * 查询列表数据
     */
    getList(val) {
      if(val){
        this.search.current = 1
      }
      if(this.time && this.time.length>0) {
        this.search.startDate = this.time[0]
        this.search.endDate = this.time[1]
      } else {
        this.search.startDate = ''
        this.search.endDate = ''
      }
      dataPage(this.search).then((res) => {
        if (res.code === 200) {
          const { records, total } = res.data;
          this.tableData = records;
          this.total = total;
        }
      });
    },
    handleSizeChange(e) {
      this.search.current = 1
      this.search.size = e;
      this.getList();
    },
    handleCurrentChange(e) {
      this.search.current = e;
      this.getList();
    },
    renderHeader(h){
      return h('div',{
        attrs:{
          class:'',
        },
        domProps:{
          innerHTML:'关联事件</br>(已处理数/总数)',
        },
      });
    },
  }
}
</script>

<style scoped>
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 10px;
  background-color: #ffffff;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  height: auto;
}
.el-form-item--small{
  margin-bottom: 10px;
}
.el-pagination{
  margin: 15px 0 5px;
}
</style>